<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-buttons">
        <Button v-if="id" _key="btnCustomerCreate" title="Lead" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="customerCreate" :clicked="create" />
      </div>
      <div slot="content-main">
        <CustomerSupplierCreateUpdate :formName="panel.formName" :isLead="true" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>
import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import CustomerSupplierCreateUpdate from "../../../components/modules/shared/customer-supplier/CustomerSupplierCreateUpdate.vue";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "LeadCreateView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    CustomerSupplierCreateUpdate,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "CRM",
        title: "Lead",
        formName: "leadCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "customerCreate",
      });
      this.removeLoading(["btnCustomerCreate"]);
    },
  },
};
</script>
